.neck-labels-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .neck-labels-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .neck-labels-header h2 {
    font-size: 1.5rem;
    color: #333;
  }
  
  /* Toggle Switch Styling */
  .toggle-switch {
    display: flex;
    align-items: center;
  }
  
  .toggle-switch input {
    display: none;
  }
  
  .toggle-slider {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: 0.4s;
    margin-right: 10px;
  }
  
  .toggle-slider::before {
    content: '';
    position: absolute;
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .toggle-switch input:checked + .toggle-slider {
    background-color: #2ecc71;
  }
  
  .toggle-switch input:checked + .toggle-slider::before {
    transform: translateX(26px);
  }
  
  .toggle-label {
    color: #666;
    font-size: 0.9rem;
  }
  
  /* File Upload Section */
  .file-upload-section {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
  }
  
  .file-upload-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .file-input {
    display: none;
  }
  
  .file-label {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .file-label:hover {
    background-color: #2980b9;
  }
  
  .file-name {
    flex-grow: 1;
    color: #666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .upload-button:not(:disabled):hover {
    background-color: #27ae60;
  }
  
  .file-progress {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .file-progress-bar {
    height: 100%;
    background-color: #3498db;
    transition: width 0.5s ease;
  }
  
  .progress-text {
    display: block;
    text-align: center;
    color: #666;
    font-size: 0.8rem;
    margin-top: 5px;
  }
.product-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    margin-top: 20px; /* Adjusted to align better with the layout */
}

/* Product card styles */
.product-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 250px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
    text-align: center;
    flex: 1 1 calc(33.333% - 40px); /* 3 cards per row on desktop */
}

.product-card:hover {
    transform: scale(1.05);
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
}

.tshirt-name {
    font-size: 1.2em;
    margin: 10px 0;
}

.color-swatches {
    display: flex;
    gap: 10px;
  }
  
  .color-swatch {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    border: 2px solid #ccc;
    transition: border-color 0.3s ease;
  }
  
  .color-swatch.selected {
    border-color: #000;
  }
  
  /* .color-swatch .color-code {
    position: absolute;
    bottom: 5px;
    left: 5px;
    color: white;
    font-size: 10px;
    font-weight: bold;
  } */
  

/* Responsive styles */
@media (max-width: 900px) {
    .product-card {
        flex: 1 1 calc(50% - 40px); /* 2 cards per row on tablet/phone */
    }
}

@media (max-width: 600px) {
    .product-card {
        flex: 1 1 calc(100% - 40px); /* 1 card per row on smaller screens */
    }
}



.design-upload-container {
  display: flex;
  gap: 20px;
  background-color: #fff !important;
  overflow: hidden;
}

.customize-button{
  display: none;
}

.error {
  color: red;
  font-size: 15px;
  margin-top: 5px;
}


.form-wrapper {
  flex: 1;
  max-width: 400px;
  min-height: 80vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-wrapper h2 {
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
}

.desktop-submit {
  background-color: #affebf;
  color: #000;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}


.desktop-submit:hover{
  background-color: #000;
  color: #affebf;
}


.desktop-draft:hover{
  background-color: #000;
  color: #fff;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.submit-button{
  display: none;
}


.save-submit{
  background-color: #000;
  color:#fff;
}

.left-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  padding: 20px;
  max-height: 100vh;
}


/* Full-screen loading overlay */
.loading-overlay {
  position: absolute;
  top: 400px;
  left: 355px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 2;
}

.loader-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}

.loader-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.loading-text {
  font-size: 16px;
  color: #3b3e43;
  font-weight: 500;
}

.skualert{
  font-size: 15px;
}




.view-select {
  padding: 8px 12px;
  margin-top: 6.5px;
  font-size: 1.0rem;
  border-radius: 4px;
  border: 1px solid #000;
  cursor: pointer;
  background-color: #f5f5f5;
  transition: all 0.3s ease;
}


.control-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.2s;
}

.control-button:hover {
  background-color: #0056b3; 
  transform: scale(1.05); 
}

.control-button:active {
  background-color: #004494; 
}



.preview-controls {
  position: absolute;
  display: flex;
  gap: 10px; 
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  top: 50px; /* Adjust the top offset as needed */
  margin-top: 20px;
}

.view-and-customize {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-bottom: 15px; */
  width: 100%; /* Ensure full width for centering */
  text-align: center;
  /* border: 2px solid brown; */
}

.preview-controls button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s ease;
}

.preview-controls button:hover {
  color: #007bff;
}


.progress-container {
  width: 50px;       /* Set a smaller width */
  height: 50px;      /* Set a smaller height */
  margin: 0 auto;    /* Center the progress bar */
}

.circular-progressbar .text {
  font-size: 8px;     /* Adjust font size of the percentage text */
}


.print-method-container {
  text-align: center;
  margin-bottom: 20px;
}

.print-method-container h3 {
  font-weight: bold;
}

.print-method-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.print-method-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.print-method-button.active {
  background-color: #0755ae;
}

.print-method-button .print-method-label {
  font-size: 12px;
  color: #FF3E4D; /* Customize this color for the specific label */
}


canvas {

  height: auto;
  display: block;

  
}

.drawer-header, .close-drawer , .customize-button{
  display: none;
}


.custom-file-upload {
  display: inline-block;
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 8px;
  background-color: white;
  font-size: 14px;
  color: black;
  text-align: center;
}

.custom-file-upload:hover {
  background-color: #f0f0f0;
}

.custom-file-upload:active {
  background-color: #e0e0e0;
}

.canvas-wrapper {
  width: 440px; 
  height: 540px; 
  overflow: hidden; 
  position: relative;
}

.canvas-wrapper canvas {
  transform: scale(0.2); 
  transform-origin: top left; 
  position: absolute;
  top: 0;
  left: 0;
}



.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-bottom: 15px; */
  width: 100%; /* Ensure full width for centering */
  text-align: center;
  /* border: 2px solid brown; */
}


.desktop-draft{
  background-color: #d5ebff;
  color:#000;
}


.size-chart-link span {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Add this CSS to style the loader and freeze the background */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it appears on top of other content */
}

.loader-overlay img {
  width: 100px; /* Adjust the size of the loader */
  height: auto;
}


.progress p {
  font-weight: bold; /* Make the text bold */
  font-size: 24px; /* Increase the font size */
  color: white; /* Ensure the text color is visible on the overlay */
  margin-top: 10px; /* Add some space between the image and the text */
}

.shopifyi {
  font-size: 1.5rem;
  /* margin-right: 7px; */
  vertical-align: sub; /* Moves the icon slightly lower */
}

.size-chart img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}


.color-swatches .swatch-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.color-swatch {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #464545;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.color-swatch.selected {
  transform: scale(1.1);
}

.color-swatch:hover {
  transform: scale(1.1);
}



@media (max-width: 768px) {

  .drawer-header, .close-drawer , .customize-button{
    display: block !important;
  }

  .drawer {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 200%;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    transition: left 0.3s ease;
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000;
  }

  .drawer.open {
    left: 0;
    overflow: visible;
  }

  .drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .close-drawer {
    background: #000;
    border: none;
    font-size: 15px;
    cursor: pointer;
    margin-left: 50px;
    margin-bottom: 10px;
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
  }




  .submit-button{
    display: block;
  }

  .form-wrapper {
    flex: 1;
    min-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }


  

  .customize-button{
    display: block;
  }

  .view-and-customize {
    display: flex;
    justify-content: center;
    align-items: center;
     gap: 5px; 
     margin-right: 0;
    /* margin-bottom: 15px; */
    width: 100%; 
    text-align: center;
    /* justify-content: space-around; */
    /* border: 2px solid brown; */
  }



  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  canvas {
    /* height: auto; */
    display: block;
    
  }
  
  .mockup{
    align-content: center;
  }

  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .submit-button {
    /* position: fixed; */
    /* bottom: 20px; */
    background-color: #007bff;
    color: white;
    border: none;
   padding: 15px;
    border-radius: 15px;
   width: 80%; 
    cursor: pointer;
    font-size: 1.1rem;
  }


  .submit-button:hover {
    background-color: #0056b3;
  }

    .customize-button {
      background-color: #1954eb;
      color: #fff;
    }

 
  
    

    /* Ensure there's enough space for the preview controls */
    .preview-controls {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-bottom: 10px; /* Adjusted to add more space below the controls */
      width: 100%;
      margin-top: 10px;
      border: 2px solid green;
    }
  
    .preview-controls button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      color: #333;
      transition: color 0.3s ease;
    }
  
    .preview-controls button:hover {
      color: #007bff;
    }
  

  }
  
  .loading-indicator {
    font-size: 16px;
    color: #007bff; /* Change to your desired color */
    margin: 10px 0;
}


.Beee {
  display: flex;
  /* justify-content: space-between; */
  gap: 5px; /* Adjust space between buttons */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.submit-button, .draft-button {
  /* Add your styles for the buttons here */
  padding: 10px 20px;
  font-size: 16px;
}

/* For mobile or smaller screens */
@media (max-width: 768px) {
  .Beee {
    flex-direction: row; /* Align buttons side by side */
    justify-content: center;
  }

  .submit-button, .draft-button {
    flex: 1 1 auto; /* Allow buttons to stretch equally */
    margin-right: 10px; /* Space between the buttons */
    max-width: 45%; /* Optional: restrict maximum width of buttons on smaller screens */
  }
}
/* RTOinventory.css */
.user-inventory-panel {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
}

.inventory-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.inventory-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 280px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.inventory-card:hover {
    transform: translateY(-5px);
}

.inventory-images {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.image-container {
    width: 45%;
    text-align: center;
}

.image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.inventory-details {
    text-align: left;
}

.size-counts p {
    margin: 5px 0;
}

strong {
    font-weight: bold;
}

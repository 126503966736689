/* .orders-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.orders-table th,
.orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.orders-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.orders-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.orders-table tr:hover {
    background-color: #f1f1f1;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
    border: none;
    cursor: pointer;
}

.confirm-button {
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #e53935;
} */


/* OrdersPage.css */
.orders-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.orders-container {
    display: flex;
    flex: 1;
    width: 100%;
    /* margin: 0 auto; */
    /* padding: 20px; */
}

.main-content {
    flex: 1;
    padding: 20px;
    background-color: #fff; /* Optional background color */
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.orders-table th,
.orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.orders-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.orders-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.orders-table tr:hover {
    background-color: #f1f1f1;
}

/* Button styles */
.confirm-button,
.cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
    border: none;
    cursor: pointer;
}

.confirm-button {
    background-color: #4CAF50 !important;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #e53935;
}

.ordersp{
    text-align: center;
    font-weight: bold;
    
}

/* Modal styles */
.modals {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-contents {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-contents h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.modal-contents p {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
}

.modal-contents button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-contents button.confirm-btn {
    background-color: #4CAF50;
    color: white;
}

.modal-contents button.confirm-btn:hover {
    background-color: #45a049;
}

.modal-contents button.cancel-btn {
    background-color: #f44336;
    color: white;
}

.modal-contents button.cancel-btn:hover {
    background-color: #e53935;
}



.date-filter-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

.date-input-group {
    display: flex;
    flex-direction: column;
}

.date-input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.date-input-group label {
    margin-bottom: 5px;
    font-size: 0.9em;
}


@media (max-width: 768px) {

    .hideee{
      display: none;
    }
}



/* Css added for modal designupload.css affecting*/

.filters-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  .date-filters {
    display: flex;
    gap: 20px;
  }
  
  .date-filter {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .date-filter label {
    font-weight: 600;
  }
  
  .date-filter input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .order-id-cell {
    cursor: pointer;
    color: #0066cc;
    text-decoration: underline;
  }
  
  .order-id-cell:hover {
    color: #004999;
  }
  
  /* Modal styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    /* background-color: white; */
    width: 80%;
    max-width: 800px;
    max-height: 90vh;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #eee;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    border-top: 1px solid #eee;
    gap: 10px;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #555;
  }
  
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .form-row .form-group {
    flex: 1;
    margin-bottom: 0;
  }
  
  .form-group label {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .order-item {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  
  .save-btn, .cancel-btn {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
  }
  
  .cancel-btn {
    background-color: #f1f1f1;
    color: #333;
    border: 1px solid #ddd;
  }
  
  .save-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn:hover {
    background-color: #e6e6e6;
  }


.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    border: none;
    /* background: #ddd; */
     background: black;
    cursor: pointer;
    border-radius: 5px;
}

.tabs button.active {
    background: black;
    color: white;
}

.settings-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.settings-form label {
    font-weight: bold;
}

.settings-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-button {
    background: black;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.error {
    color: red;
}

.success {
    color: green;
}
